import * as React from "react"
import { Link } from "gatsby"
import Helmet from "react-helmet";
import Layout from "../components/Layout";
import Container from "@mui/material/Container/Container";
import Grid from "@mui/material/Grid";
import {MyImg, SubTitleWhiteBox} from "../components/Common/Base";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button/Button";
import {MyLink, MyLinkLight, MyLinkLightIn} from "../components/Common/Links";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="title" content={'赑玄阁'} />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <title>404</title>
      </Helmet>
      <Container
        maxWidth="lg"
        sx={{
          pt: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          },
          pb: {
            xs: 6,
            sm: 12,
            md: 18,
            lg: 24
          }
        }}
      >
        <Grid
          container
          alignItems="center"
          spacing={{
            xs: 2,
            md: 4,
            lg: 6,
          }}
        >
          <Grid
            item
            xs={6}
          >
            <MyImg width="100%" src={require('../images/404.png').default} />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Box4Title
              variant="h4"
              sx={{
                color: '#76fb4c'
              }}
            >
              404
            </Box4Title>
            <SubTitleWhiteBox variant="subtitle1" gutterBottom>
              输入地址有误或改地址已删除
              <br />
              你可试返回首页，或尝试搜索其他内容。
            </SubTitleWhiteBox>
            <MyLink
              to={'/'}
              style={{
                background: '#333333',
                borderRadius: '3px',
                padding: '7px 15px'
              }}
            >
              首页
            </MyLink>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default NotFoundPage


const Box4Title = styled(Typography)(({theme}) => {
  return {
    color: '#1BAFBF',
    fontSize: '32px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      fontSize: '48px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '64px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '84px',
    }
  }
});
